<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">Oops!</h4>
            <p class="text-muted">
                {{ StoreLangTranslation.data['the_page_you_are_looking_for_was_not_found'][StoreLang] }}
            </p>
          </div>
          <!--
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-search"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">
              <b-input-group-append>
                <b-button variant="info">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Page404',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreLang: 'StoreLang',
    StoreLangTranslation: 'StoreLangTranslation',
  }),
}
</script>
