<template>
  <div :style="f_calculateLoginGeneralStyle()">
    <template v-if="d_pageShow">
      <b-container fluid>
        <b-row align-v="center" align-h="center" :style="p_registerType !== 'register_by_authorized_person' ? 'height: 100vh' : ''">
          <b-col sm="10" md="8" lg="6" align-v="center">
            <b-card align="center" align-v="center" id="at" :style="f_styleLoginCardOut()">
              <div>
                <b-row>
                  <b-col cols="2"> </b-col>
                  <b-col cols="8">
                    <h4 :style="f_calculateLoginApplicationNameStyle()">
                      <span v-if="StoreFrontendSettings.data.application_name && StoreFrontendSettings.data.application_name[StoreLang]" @click="f_goToMainPage()" style="cursor: pointer;"> {{ StoreFrontendSettings.data.application_name[StoreLang] }}</span>
                    </h4>
                  </b-col>
                  <b-col cols="2">
                    <b-dropdown variant="white" style="padding: 0px;" no-caret>
                      <template slot="button-content">
                        <span style="color: whitesmoke; font-size: 15px;">
                          {{ d_selectedLang }}
                        </span>
                      </template>
                      <template v-for="(lang, lang_index) in StoreLangList.data">
                        <b-dropdown-item @click="f_selectLang(lang)">
                          {{ lang.label }}
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </div>
              <b-card :style="f_styleLoginCardIn()">
                <h5 style="color: black;">
                  <b-row>
                    <b-col cols="4" style="padding-left: 15px; padding-top: 0px; text-align: left"></b-col>
                    <b-col cols="4" style="padding-left: 15px; padding-top: 0px; text-align: left; text-align: center;">
                      {{ StoreLangTranslation.data['register'][StoreLang] }}
                    </b-col>
                    <b-col cols="4" style="padding-left: 15px; padding-top: 0px; text-align: left"></b-col>
                  </b-row>                
                </h5>
                <b-row style="padding: 3px;">
                  <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    {{ StoreLangTranslation.data['username'][StoreLang] }}
                  </b-col>
                  <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    <b-form-input type="text" class="form-control-warning" id="username" key="username" :formatter="f_toLowerCase(d_newUserData.username, 'username')" v-model="d_newUserData.username" :state="f_usernameRegexCheck(d_newUserData.username)"></b-form-input>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>
                    {{ StoreLangTranslation.data['username_format'][StoreLang] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row style="padding: 3px;">
                  <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    {{ StoreLangTranslation.data['firstname'][StoreLang] }}
                  </b-col>
                  <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    <b-form-input type="text" class="form-control-warning" id="first_name" key="first_name" v-model="d_newUserData.first_name" :state="d_newUserData.first_name.length > 0"></b-form-input>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>
                      {{ StoreLangTranslation.data['please_write_firstname'][StoreLang] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row style="padding: 3px;">
                  <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    {{ StoreLangTranslation.data['lastname'][StoreLang] }}
                  </b-col>
                  <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    <b-form-input type="text" class="form-control-warning" id="last_name" key="last_name" v-model="d_newUserData.last_name" :state="d_newUserData.last_name.length > 0"></b-form-input>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>
                      {{ StoreLangTranslation.data['please_write_lastname'][StoreLang] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
  <!--               <b-row style="padding: 3px;">
                  <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    {{ StoreLangTranslation.data['gender'][StoreLang] }}
                  </b-col>
                  <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    <b-form-select v-model="d_newUserData.gender" class="form-control-warning" id="gender" key="gender">
                      <option v-for="(op, op_index) in d_userWdm['0']['parameters']['5'].options" :value="op.value">
                        {{ op.translation[StoreLang] }}
                      </option>
                    </b-form-select>
                  </b-col>
                </b-row>
   -->
  <!--               <b-row style="padding: 3px;">
                  <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    {{ StoreLangTranslation.data['date_of_birth'][StoreLang] }}
                  </b-col>
                  <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    <b-form-input type="date" class="form-control-warning" id="date_of_birth" key="date_of_birth" v-model="d_newUserData.date_of_birth" :state="d_newUserData.date_of_birth.length > 0"></b-form-input>
                  </b-col>
                </b-row> -->
                <b-row style="padding: 3px;">
                  <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    {{ StoreLangTranslation.data['phone_number'][StoreLang] }}
                  </b-col>
                  <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    <b-row>
                      <b-col cols="5">
                        <b-form-select v-model="d_newUserData.phone_area_code" class="form-control-warning" id="phone_area_code" key="phone_area_code">
                          <option v-for="(phone_area_code, phone_area_code_index) in f_phoneAreaCodeList" :value="phone_area_code.code">
                            {{ phone_area_code.translation[StoreLang] + ' - ' + phone_area_code.code }}
                          </option>
                        </b-form-select>
                      </b-col>
                      <b-col cols="7">
                        <b-form-input type="number" class="form-control-warning" id="phone_number" key="phone_number" v-model="d_newUserData.phone_number" :state="f_phoneRegexCheck(d_newUserData.phone_number)"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" style="text-align: right;">
                        {{ d_newUserData.phone_area_code + d_newUserData.phone_number }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row style="padding: 3px;">
                  <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    {{ StoreLangTranslation.data['mail'][StoreLang] }}
                  </b-col>
                  <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    <b-form-input type="text" class="form-control-warning" id="email" key="email" :formatter="f_toLowerCase(d_newUserData.email, 'email')" v-model="d_newUserData.email" :state="f_emailRegexCheck(d_newUserData.email)"></b-form-input>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>
                      {{ StoreLangTranslation.data['please_write_true_mail'][StoreLang] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row style="padding: 3px;">
                  <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    {{ StoreLangTranslation.data['password'][StoreLang] }}
                  </b-col>
                  <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    <span @click="f_generateRandomPassword()" style="padding-left: 15px; cursor: pointer; text-decoration: underline; font-style: italic;">
                    {{ StoreLangTranslation.data['password_create'][StoreLang] }}
                    </span>
                    <b-input-group label-for="password" label-cols="6" validated>
                      <b-form-input :type="d_showPasswordType" class="form-control-warning" id="password" key="password" v-model="d_newUserData.password" :state="f_checkPasswordFilled()"></b-form-input>
                      <b-input-group-append>
                        <b-input-group-text style="cursor: pointer;" @click="f_showPasswordType()">
                          <i class="fa fa-eye"></i>
                        </b-input-group-text>
                      </b-input-group-append>
                      <b-form-valid-feedback></b-form-valid-feedback>
                      <b-form-invalid-feedback>
                        {{ StoreLangTranslation.data['password_minimum_eight_characters'][StoreLang] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row style="padding: 3px;">
                  <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    {{ StoreLangTranslation.data['password_retype'][StoreLang] }}
                  </b-col>
                  <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left">
                    <b-form-input :type="d_showPasswordType" class="form-control-warning" id="d_passwordRepeat" key="d_passwordRepeat" v-model="d_passwordRepeat" :state="f_checkPasswordFilled()"></b-form-input>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>
                      {{ StoreLangTranslation.data['password_minimum_eight_characters'][StoreLang] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div id="captcha"></div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button size="sm" @click="f_createCaptcha()" variant="white"><i class="fa fa-edit" style="color: #45dc45;"></i>&nbsp; {{ StoreLangTranslation.data['change'][StoreLang] }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group validated label-for="normalCaptcha">
                      <b-form-input id="captchaCode" type="text" :placeholder="StoreLangTranslation.data['write_captcha_code'][StoreLang]" required v-model="d_captchaWrittenCode"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <template v-if="p_registerType !== 'register_by_authorized_person'">
                   <b-row style="padding: 3px;" v-if="StoreFrontendSettings.data.terms_of_service_link && StoreFrontendSettings.data.terms_of_service_link[StoreLang]">
                      <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left;">
                        <a :href="StoreFrontendSettings.data.terms_of_service_link[StoreLang]" target="_blank">
                          {{ StoreLangTranslation.data['terms_of_service_show'][StoreLang] }}
                          <i class="fa fa-external-link"></i> 
                        </a>
                      </b-col>
                      <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left;">
                        <b-form-checkbox v-model="d_terms_of_service" :state="d_terms_of_service">
                          {{ StoreLangTranslation.data['terms_of_service_approve'][StoreLang] }}
                        </b-form-checkbox>
                        <!-- <b-form-invalid-feedback :state="d_terms_of_service">invalid</b-form-invalid-feedback>
                        <b-form-valid-feedback :state="d_terms_of_service">valid</b-form-valid-feedback> -->
                      </b-col>
                  </b-row>
                   <b-row style="padding: 3px;" v-if="StoreFrontendSettings.data.privacy_policy_link && StoreFrontendSettings.data.privacy_policy_link[StoreLang]">
                      <b-col cols="5" style="padding-left: 15px; padding-top: 0px; text-align: left;">
                        <a :href="StoreFrontendSettings.data.privacy_policy_link[StoreLang]" target="_blank">
                          {{ StoreLangTranslation.data['privacy_policy_show'][StoreLang] }}
                          <i class="fa fa-external-link"></i> 
                        </a>
                      </b-col>
                      <b-col cols="7" style="padding-left: 15px; padding-top: 0px; text-align: left;">
                        <b-form-checkbox v-model="d_privacy_policy" :state="d_privacy_policy">
                          {{ StoreLangTranslation.data['privacy_policy_approve'][StoreLang] }}
                        </b-form-checkbox>
                        <!-- <b-form-invalid-feedback :state="d_terms_of_service">invalid</b-form-invalid-feedback>
                        <b-form-valid-feedback :state="d_terms_of_service">valid</b-form-valid-feedback> -->
                      </b-col>
                  </b-row>
                </template>

  <!--               <b-row style="padding: 3px;">
                    <b-col cols="6" style="padding-left: 3px; padding-top: 0px;">
                      <b-form-checkbox v-model="d_eula" :state="d_eula">EULA Onayı</b-form-checkbox>
                      <b-form-invalid-feedback :state="d_eula"><span @click="d_showEULA = true;" style="cursor: pointer; text-decoration: underline;">Metni Görüntüle</span></b-form-invalid-feedback>
                      <b-form-valid-feedback :state="d_eula"><span @click="d_showEULA = true;" style="cursor: pointer; text-decoration: underline;">Metni Görüntüle</span></b-form-valid-feedback>
                    </b-col>
                    <b-col cols="6" style="padding-left: 3px; padding-top: 0px;">
                      <b-form-checkbox v-model="d_kvkk" :state="d_kvkk">KVKK Onayı</b-form-checkbox>
                      <b-form-invalid-feedback :state="d_kvkk"><span @click="d_showKVKK = true;" style="cursor: pointer; text-decoration: underline;">Metni Görüntüle</span></b-form-invalid-feedback>
                      <b-form-valid-feedback :state="d_kvkk"><span @click="d_showKVKK = true;" style="cursor: pointer; text-decoration: underline;">Metni Görüntüle</span></b-form-valid-feedback>
                    </b-col>
                </b-row>-->
                <div slot="footer">
                  <b-button size="sm" style="background-color: #368a9b;width: 100px;color: #c1e4e1;" @click="f_resetForm()"><i class="fa fa-ban" style="color: #f87574;"></i>
                    {{ StoreLangTranslation.data['clear'][StoreLang] }}
                  </b-button>
                 <template v-if="!d_showRegisterButtonClicked">
                    <b-button :disabled="!f_checkFormFilled()" size="sm" style="background-color: rgb(54, 138, 155);width: 100px;color: #c1e4e1;" @click="f_startRegister()"><i class="fa fa-sign-in" style="color: #45dc45;"></i>
                      {{ StoreLangTranslation.data['register'][StoreLang] }}
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="sm" :style="f_styleLoginLoading()"><i class="fa fa-sign-in" style="color: #45dc45;"></i>&nbsp; {{ StoreLangTranslation.data['loading'][StoreLang] }}
                    </b-button>
                  </template>
                </div>
              </b-card>
              <template v-if="p_registerType !== 'register_by_authorized_person'">
                <div style="padding: 10px;">
                  <span @click="f_goToMainPage()" style="cursor: pointer; color: white;">{{ StoreLangTranslation.data['go_to_dashboard'][StoreLang] }}</span>
                </div>
                <div style="padding: 10px;">
                  <span @click="f_goToLoginPage()" style="cursor: pointer; color: white;">{{ StoreLangTranslation.data['login'][StoreLang] }}</span>
                </div>
                <div style="padding: 10px;">
                  <small v-if="StoreFrontendSettings.data.login_slogan_text && StoreFrontendSettings.data.login_slogan_text[StoreLang]" style="color:white; text-align: center; display: inline-block; margin-bottom: 10px; margin-top: 10px;">
                  {{ StoreFrontendSettings.data.login_slogan_text[StoreLang] }}
                  </small>
                </div>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <modal v-if="d_showEULA" @close="d_showEULA = false" large="True">
        <h3 slot="header"> EULA Metni </h3>
        <div slot="body" v-html="d_agreements.eula">
          {{ d_agreements.eula }}
        </div>
        <div slot="footer">
          <button type="button" class="btn btn-danger" @click="d_showEULA = false">{{ $t('wdm16.4053') }}</button>
        </div>
      </modal>
      <modal v-if="d_showKVKK" @close="d_showKVKK = false" large="True">
        <h3 slot="header"> KVKK Aydınlatma Metni </h3>
        <div slot="body" v-html="d_agreements.kvkk">
          {{ d_agreements.kvkk }}
        </div>
        <div slot="footer">
          <button type="button" class="btn btn-danger" @click="d_showKVKK = false">{{ $t('wdm16.4053') }}</button>
        </div>
      </modal>
    </template>
    <template v-if="d_page404Show">
      <Page404></Page404>
    </template>
  </div>
</template>

<script>
import auth from '@/auth';
import { data as phone_area_code_list } from '@/wam/options/phone_area_code_list';
import { data as lang_list } from '@/wam/options/lang_list';
import wdm16 from '@/wdm16';
import store from '@/store';
import { WisdomRegex } from '@/public/WisdomRegex';
import { mapGetters } from 'vuex';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as Page404
} from '@/wam/components/widgets/Page404';
import {
  default as Modal
} from '@/components/widgets/Modal';
export default {
  name: 'register',
  components: {
    Modal,
    Page404
  },
  props: {
    p_viewMode: {
      type: String,
      required: false
    },
    p_registerType: {
      type: String,
      required: false
    }
  },
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreUser: 'StoreUser',
    StoreLoading: 'StoreLoading',
    StoreModal: 'StoreModal',
    StoreLang: 'StoreLang',
    StoreCurrencyType: 'StoreCurrencyType',
    StoreCurrencyTypeList: 'StoreCurrencyTypeList',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreFrontendWebsiteunit: 'StoreFrontendWebsiteunit',
    StoreMenuData: 'StoreMenuData',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreShopCartList: 'StoreShopCartList',
    StoreShopCartOrderList: 'StoreShopCartOrderList',
  }),
  data () {
    return {
      d_showRegisterButtonClicked: false,
      d_captchaUse: false,
      d_captchaCode: '',
      d_captchaWrittenCode: '',
      d_pageShow: false,
      d_page404Show: false,
      f_phoneAreaCodeList: phone_area_code_list,
      d_selectedLang: 'tr',
      d_agreements: require('@/wam/options/agreements').agreements,
      wdm16: wdm16.wdm16,
      backgroundImage: require('@/icon/LoginBackground.png'),
      store: store,
      d_passwordRepeat: '',
      d_newUserData: {
        'email': '',
        'first_name': '',
        'last_name': '',
        'password': '',
        'username': '',
        'promocode': '',
        'is_admin_register': 0,
        'gender': '',
        'active_lang': '0',
        'date_of_birth': '',
        'phone_number': '',
        'phone_area_code': '0090',
      },
      d_formCheckList: { 'email': false, 'password': false, 'username': false, 'phone': false },
      d_privacy_policy: false,
      d_terms_of_service: false,
      d_eula: false,
      d_kvkk: false,
      d_showEULA: false,
      d_showKVKK: false,
      d_isAdminRegister: 0,
      d_promoCodeList: [],
      d_showPasswordType: "password"
    };
  },
  created: function () {
    if (this.p_viewMode !== 'only_show') {
      if (this.p_registerType === 'register_by_authorized_person') {
        this.d_pageShow = true;
        this.d_captchaUse = true;
      } else {
        auth.logout();
        if (this.StoreFrontendSettings.data.register_function === '1') {
          this.d_pageShow = true;
          this.d_captchaUse = true;
        } else {
          this.d_page404Show = true;
        }
      }
    }
    /*
      if (this.$router.currentRoute.name === 'administrator_register') {
        this.d_isAdminRegister = 1;
        this.f_preparePromocodeList();
      }
      let wisdomera_system = JSON.parse(localStorage.getItem('wisdomera_system'));
      if (wisdomera_system.system_type === 'customer') {
        this.$router.push('/dashboard');
      }
    */
  },
  mounted: function () {
    /*
      if (this.$router.currentRoute.query.promocode) {
        this.f_fillUriPromocode();
      }
    */
    if (this.d_captchaUse) {
      this.f_createCaptcha();
    }
  },
  methods: {
    f_calculateLoginGeneralStyle: function () {
      let style = '';
      let image = '';
      if (this.p_registerType !== 'register_by_authorized_person') {
        let image = '';
        // let image = 'https://cdn.wisdomera.io/images/wisdomera_login_background.png';
        // let image = 'https://cdn.wisdomera.io/evideep/4134.jpg';
        if (this.StoreFrontendSettings.data.login_background_image) {
          image = this.StoreFrontendSettings.data.login_background_image;
        }
        if (image) {
          style = 'background-image: url(' + image + '); background-size: cover; background-repeat: no-repeat;';
        } else {
          let login_background_color = 'white';
          if (this.StoreFrontendSettings.data.login_background_color) {
            login_background_color = this.StoreFrontendSettings.data.login_background_color;
          }
          style += 'background-color: ' + login_background_color + ';';
        }
      }
      return style;
    },
    f_calculateLoginApplicationNameStyle: function () {
      let style = '';
      let login_card_out_text_color = 'black';
      if (this.StoreFrontendSettings.data.login_card_out_text_color) {
        login_card_out_text_color = this.StoreFrontendSettings.data.login_card_out_text_color;
      }
      style += 'color: ' + login_card_out_text_color + ';';
      return style;
    },
    f_styleLoginBackground: function () {
      let style = '';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_background_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      // console.log('style : ', style);
      return style;
    },
    f_styleLoginCardOut: function () {
      let style = '';
      style += 'margin-top: 50px;';
      style += 'border-radius: 20px;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_card_out_background_color;
      } catch (err) {}

      let y = 'green';
      try {
        y = this.StoreFrontendSettings.data.login_card_out_shadow_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'box-shadow: 5px 5px 10px ' + y + ';';
      // console.log('style : ', style);
      style += 'opacity: 0.9;';
      return style;
    },
    f_styleLoginCardIn: function () {
      let style = '';
      style += 'margin-top: 0px;';
      style += 'border-radius: 15px;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_card_in_background_color;
      } catch (err) {}
      let y = 'green';
      try {
        y = this.StoreFrontendSettings.data.login_card_in_shadow_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'box-shadow: 2px 2px 3px ' + y + ';';
      style += 'opacity: 0.9;';
      // console.log('style : ', style);
      return style;
    },
    f_styleLoginButton: function () {
      let style = '';
      style += 'margin: 2px;';
      style += 'width: 100px;';
      style += 'color: white;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_buttons_background_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'border-color: ' + x + ';';
      // console.log('style : ', style);
      return style;
    },
    f_styleLoginInput: function () {
      let style = '';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_inputs_border_color;
      } catch (err) {}

      style += 'border: 1px solid ' + x + ';';
      // console.log('style : ', style);
      return style;
    },
    f_styleLoginLoading: function () {
      let style = '';
      style += 'margin: 2px;';
      style += 'width: 100px;';
      style += 'color: white;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_loading_background_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'border-color: ' + x + ';';
      // console.log('style : ', style);
      return style;
    },
    f_createCaptcha: function() {
      this.d_captchaWrittenCode = '';
      this.d_captchaCode = '';
      //clear the contents of captcha div first 
      document.getElementById('captcha').innerHTML = "";
      let charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
      let lengthOtp = 6;
      let captcha = [];
      for (let i = 0; i < lengthOtp; i++) {
        //below code will not allow Repetition of Characters
        let index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
        if (captcha.indexOf(charsArray[index]) == -1)
          captcha.push(charsArray[index]);
        else i--;
      }
      let canv = document.createElement("canvas");
      canv.id = "captcha";
      canv.width = 100;
      canv.height = 50;
      let ctx = canv.getContext("2d");
      ctx.font = "25px Georgia";
      ctx.strokeText(captcha.join(""), 0, 30);
      //storing captcha so that can validate you can save it somewhere else according to your specific requirements
      this.d_captchaCode = captcha.join("");
      // console.log('this.d_captchaCode : ', this.d_captchaCode);
      document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
      this.$forceUpdate();
    },
    f_goToMainPage: function () {
      let redirect_dashboard_path = this.f_getRedirectDashboardRoute();
      // console.log('redirect_dashboard_path : ', redirect_dashboard_path);
      if (redirect_dashboard_path[0] !== '/') {
        redirect_dashboard_path = '/' + redirect_dashboard_path;
      }

      this.$router.push({ path: redirect_dashboard_path, query: {} });
    },
    f_getRedirectDashboardRoute: function () {
      let redirect_dashboard_path = '';
      for (let lang_index in lang_list) {
        let lang_label = lang_list[lang_index]['label'];
        for (let i in this.StoreMenuData.data.list) {
          let is_dashboard = '';
          try {
            is_dashboard = this.StoreMenuData.data.list[i].is_dashboard['value'];
          } catch (err) {}
          if (is_dashboard === '1') {
            let route_path = '';
            try {
              route_path = this.StoreMenuData.data.list[i].route_path[lang_label];
            } catch (err) {}
            if (route_path) {
              redirect_dashboard_path = route_path;
              break;
            }
          }
          if (this.StoreMenuData.data.list[i].list && this.StoreMenuData.data.list[i].list.length > 0) {
            for (let sub_i in this.StoreMenuData.data.list[i].list) {
              let is_dashboard = '';
              try {
                is_dashboard = this.StoreMenuData.data.list[i].list[sub_i].is_dashboard['value'];
              } catch (err) {}
              if (is_dashboard === '1') {
                let route_path = '';
                try {
                  route_path = this.StoreMenuData.data.list[i].list[sub_i].route_path[lang_label];
                } catch (err) {}
                if (route_path) {
                  redirect_dashboard_path = route_path;
                  break;
                }
              }

            }
          }
        }
      }
      // console.log('this.StoreMenuData ', this.StoreMenuData);
      // console.log('redirect_dashboard_path : ', redirect_dashboard_path);
      return redirect_dashboard_path;
    },
    f_goToLoginPage: function () {
      this.$router.push({ path: '/login', query: {} });
    },
    f_selectLang: function (lang) {
      this.d_selectedLang = lang.label;
      localStorage.setItem('lang', this.d_selectedLang);
      localStorage.setItem('active_lang', JSON.stringify(lang));
      this.$store.commit('MutationLang', this.d_selectedLang);
      this.d_newUserData.active_lang = lang.value;
    },
    f_showPasswordType: function () {
      if (this.d_showPasswordType === 'password') {
        this.d_showPasswordType = 'text';
      } else {
        this.d_showPasswordType = 'password';
      }
    },
    f_generateRandomPassword: function () {
      var length = 10,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.d_newUserData.password = retVal;
      this.d_passwordRepeat = retVal;
    },
    f_preparePromocodeList: function () {
      ServiceFunctions.prepare_promocodes()
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_promoCodeList = resp.data.result;
          } else {
            alert(resp.data.message);
            return;
          }
        });
    },
    f_fillUriPromocode: function () {
      let tmp_promocode = this.f_formatPromocodeRegex(this.$router.currentRoute.query.promocode);
      this.d_newUserData.promocode = tmp_promocode;
    },
    f_formatPromocodeRegex (value, event) {
      return WisdomRegex.regex1(value).value;
    },
    f_resetForm: function () {
      this.d_newUserData = { 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'username': '' };
      this.d_passwordRepeat = '';
      this.d_eula = false;
      this.d_kvkk = false;
    },
    f_checkPasswordFilled: function () {
      if (this.d_passwordRepeat === this.d_newUserData.password && this.d_passwordRepeat.length >= 8) {
        let status = WisdomRegex.password(this.d_newUserData.password).status;
        this.d_formCheckList.password = status;
        return status;
      }
      this.d_formCheckList.password = false;
      return false;
    },
    f_checkFormFilled: function () {
      if (this.d_formCheckList.email && this.d_formCheckList.password && this.d_formCheckList.username && this.d_newUserData.first_name.length > 0 && this.d_newUserData.last_name.length > 0) {
        if (this.p_registerType === 'register_by_authorized_person') {
          return true;
        } else {
          if (this.d_terms_of_service && this.d_privacy_policy) {
            return true;
          }
        }
        return true;
      }
      return false;
    },
    f_usernameRegexCheck: function (username) {
      if (['wisdom', 'admin', 'administrator', 'wisdomera', 'moderator', 'system', 'sys', 'system.admin', 'sys.admin'].indexOf(username) !== -1) {
        this.d_formCheckList.username = false;
        return false;
      }

      // var regex = new RegExp("#" + stringToGoIntoTheRegex + "#", "g");
      // at this point, the line above is the same as: var regex = /#abc#/g;
      const regex = /^[\.a-z0-9]*$/ig;
      if (username) {
        let regexUsername = regex.exec(username);
        // console.log('regexUsername::::::', regexUsername)
        if (regexUsername && (username === regexUsername[0])) {
          this.d_formCheckList.username = true;
          return true;
        }
      }
      this.d_formCheckList.username = false;
      return false;
    },
    f_emailRegexCheck: function (email) {
      let status = WisdomRegex.email(email).status;
      this.d_formCheckList.email = status;
      return status;
    },
    f_phoneRegexCheck: function (phone) {
      let status = false;
      if (this.d_newUserData.phone_area_code) {
        status = WisdomRegex.phone(phone).status;
        this.d_formCheckList.phone = status;
      }
      return status;
    },
    f_toLowerCase: function (value, param) {
      this.d_newUserData[param] = value.toLowerCase()
    },
    f_startRegister: function () {
      if (this.d_captchaCode === this.d_captchaWrittenCode) {
        if (!this.d_formCheckList) {
          alert(this.StoreLangTranslation.data['please_fill_all_inputs'][this.StoreLang]);
          return;
        }
        let query = '';
        let data = this.d_newUserData;
        this.d_showRegisterButtonClicked = true;
        ServiceFunctions.register(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              if (this.p_registerType === 'register_by_authorized_person') {
                //
              } else {
                this.f_goToMainPage();
              }
              alert(this.StoreLangTranslation.data['register_done_please_mail_approve_check'][this.StoreLang]);
            } else {
              alert(resp.data.status_code + ' / ' + resp.data.status_message);
            }
            this.d_showRegisterButtonClicked = false;
          });
        } else {
          this.f_createCaptcha();
          alert(this.StoreLangTranslation.data['captcha_code_incorrect'][this.StoreLang]);
        }
    },
  },
  watch: {},
}

</script>

<style type="text/css">
canvas {
  /*background-color: #000;*/
  /*prevent interaction with the canvas*/
  pointer-events: none;
}
</style>

